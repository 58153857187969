import { navigate } from 'gatsby';
import React from 'react';

import { Input } from '@components/controls/Input';
import { Button } from '@components/controls/button';
import { StyledSelect } from '@components/controls/select';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { MypageBasicInfoDescription } from '@features/mypage/BasicInfo/components/MypageBasicInfoDescription';
import { useUserInfoForm } from '@features/mypage/BasicInfo/hooks/useUserInfoForm';
import { usePurchase } from '@hooks/usePurchase';
import { User } from '@pb/api/v1/user/common_pb';

type Props = {
  user: User.AsObject;
};

export const UserForm = ({ user }: Props) => {
  const { data, errors, handleInputChange, handleSelectChange, handleSubmit, handleZipCodeChange } =
    useUserInfoForm({
      user,
    });
  const [_, { getRegions }] = usePurchase();
  const {
    data: { regionList },
  } = getRegions();

  return (
    <Block paddingTop="22px" borderBottom="1px solid var(--color-border-gray2)">
      <MypageBasicInfoDescription
        data={data}
        header="会員情報"
        paragraphProps={{
          color: 'black',
        }}
        blockProps={{
          marginBottom: '20px',
          md: {
            marginBottom: '14px',
          },
        }}
        gridProps={{
          alignItems: 'center',
          gridTemplateColumns: '1fr',
          rowGap: '5px',
          md: {
            gridTemplateColumns: '77px 1fr',
            rowGap: '0',
          },
        }}
        titles={[
          {
            title: '名前',
            errorMessage: errors.firstName?._errors || errors.lastName?._errors,
            accessorFn: ({ firstName, lastName }) => (
              <Flex gap="10px" jc="flex-start">
                <Input
                  borderRadius="5px"
                  hasBorder
                  width="150px"
                  height="34px"
                  defaultValue={lastName}
                  onChange={handleInputChange('lastName')}
                />
                <Input
                  borderRadius="5px"
                  hasBorder
                  width="150px"
                  height="34px"
                  defaultValue={firstName}
                  onChange={handleInputChange('firstName')}
                />
              </Flex>
            ),
          },
          {
            title: 'フリガナ',
            errorMessage: errors.firstKana?._errors || errors.lastKana?._errors,
            accessorFn: ({ firstKana, lastKana }) => (
              <Flex gap="10px" jc="flex-start">
                <Input
                  borderRadius="5px"
                  hasBorder
                  width="150px"
                  height="34px"
                  defaultValue={lastKana}
                  onChange={handleInputChange('lastKana')}
                />
                <Input
                  borderRadius="5px"
                  hasBorder
                  width="150px"
                  height="34px"
                  defaultValue={firstKana}
                  onChange={handleInputChange('firstKana')}
                />
              </Flex>
            ),
          },
          {
            title: '性別',
            errorMessage: errors.gender?._errors,
            accessorFn: ({ gender }) => (
              <StyledSelect
                width="100px"
                height="34px"
                defaultValue={gender}
                onChange={handleSelectChange('gender')}
              >
                <option value="">選択してください</option>
                <option value="1">男性</option>
                <option value="2">女性</option>
                <option value="3">その他</option>
              </StyledSelect>
            ),
          },
          {
            title: '生年月日',
            errorMessage: errors.year?._errors || errors.month?._errors || errors.day?._errors,
            accessorFn: ({ year, month, day }) => (
              <Flex gap="10px" jc="flex-start">
                <StyledSelect
                  defaultValue={year}
                  width="100px"
                  height="34px"
                  onChange={handleSelectChange('year')}
                >
                  <option value="">年</option>
                  {Array.from({ length: 123 }, (_, i) => (
                    <option key={i} value={String(1900 + i)}>
                      {1900 + i}
                    </option>
                  ))}
                </StyledSelect>
                <StyledSelect
                  width="74px"
                  height="34px"
                  defaultValue={month}
                  onChange={handleSelectChange('month')}
                >
                  <option value="">月</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={String(1 + i)}>
                      {1 + i}
                    </option>
                  ))}
                </StyledSelect>
                <StyledSelect
                  width="74px"
                  height="34px"
                  defaultValue={day}
                  onChange={handleSelectChange('day')}
                >
                  <option value="">日</option>
                  {year &&
                    month &&
                    Array.from(
                      {
                        length: new Date(
                          Number(year),
                          Number(month),
                          0
                        ).getDate() /* これで月の最終日が取得できる */,
                      },
                      (_, i) => (
                        <option key={i} value={String(1 + i)}>
                          {1 + i}
                        </option>
                      )
                    )}
                </StyledSelect>
              </Flex>
            ),
          },
          {
            title: '郵便番号',
            errorMessage: errors.zipCode?._errors,
            accessorFn: ({ zipCode }) => (
              <Flex alignItems="center" gap="8px" jc="flex-start">
                <Input
                  width="100px"
                  borderRadius="5px"
                  hasBorder
                  defaultValue={zipCode}
                  onChange={handleInputChange('zipCode')}
                />
                <Button
                  onClick={() => handleZipCodeChange()}
                  radius="5px"
                  color="black"
                  width="110px"
                  height="30px"
                  bg="gray2"
                  border="1px solid #D9D9D9"
                >
                  住所自動入力
                </Button>
              </Flex>
            ),
          },
          {
            title: '都道府県',
            errorMessage: errors.prefecture?._errors,
            accessorFn: ({ prefecture }) => (
              <StyledSelect
                width="136px"
                height="34px"
                value={prefecture}
                onChange={handleSelectChange('prefecture')}
              >
                <option value="">選択してください</option>
                {regionList.map((region) => (
                  <option key={region.id} value={region.name}>
                    {region.name}
                  </option>
                ))}
              </StyledSelect>
            ),
          },
          {
            title: '市区町村',
            errorMessage: errors.municipality?._errors,
            accessorFn: ({ municipality }) => (
              <Input
                borderRadius="5px"
                hasBorder
                value={municipality}
                onChange={handleInputChange('municipality')}
              />
            ),
          },
          {
            title: '番地',
            errorMessage: errors.address1?._errors,
            accessorFn: ({ address1 }) => (
              <Input
                borderRadius="5px"
                hasBorder
                defaultValue={address1}
                onChange={handleInputChange('address1')}
              />
            ),
          },
          {
            title: '建物名',
            errorMessage: errors.address2?._errors,
            accessorFn: ({ address2 }) => (
              <Input
                borderRadius="5px"
                hasBorder
                defaultValue={address2}
                onChange={handleInputChange('address2')}
              />
            ),
          },
          {
            title: '電話番号',
            errorMessage: errors.phoneNumber?._errors,
            accessorFn: ({ phoneNumber }) => (
              <Input
                width="211px"
                borderRadius="5px"
                hasBorder
                defaultValue={phoneNumber}
                onChange={handleInputChange('phoneNumber')}
              />
            ),
          },
        ]}
      />
      <Block padding="10px 0 30px 0" margin="0 auto" width="fit-content">
        <Button
          type="submit"
          width="152px"
          height="35px"
          radius="5px"
          bg="gray2"
          border="1px solid var(--color-border-gray2)"
          color="black"
          onClick={() =>
            handleSubmit()
              .then(() => navigate('/mypage/basic-info'))
              .catch((e) => console.error(e))
          }
        >
          修正する
        </Button>
      </Block>
    </Block>
  );
};
