import React from 'react';

import { Layout } from '@components/layout';
import { Mypage } from '@features/mypage';
import { EditUser } from '@features/mypage/BasicInfo/EditUser';

const Edit = () => {
  return (
    <Layout>
      <Mypage>
        <EditUser />
      </Mypage>
    </Layout>
  );
};
export default Edit;
