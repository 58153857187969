import _ from 'lodash';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { ZodFormattedError } from 'zod';

import { useAddress } from '@features/mypage/BasicInfo/hooks/useAddress';
import { UserInfoSchema } from '@features/mypage/BasicInfo/schemas';
import useUser from '@hooks/useUser';
import { User } from '@pb/api/v1/user/common_pb';

type DateValue = {
  year: string;
  month: string;
  day: string;
};

type Props = {
  user: User.AsObject;
};
type DataState = User.AsObject & DateValue;

export const useUserInfoForm = ({ user }: Props) => {
  const { updateUser } = useUser();
  const { getAddress } = useAddress();

  const [errors, setErrors] = useState<Partial<ZodFormattedError<DataState>>>({});
  const [data, setData] = useState<DataState>(
    _.merge(_.cloneDeep(user), {
      year: new Date(user.birthday).getFullYear().toString(),
      month: (new Date(user.birthday).getMonth() + 1).toString(),
      day: new Date(user.birthday).getDate().toString(),
    })
  );

  const handleInputChange = useCallback(
    (path: keyof DataState): ChangeEventHandler<HTMLInputElement> =>
      (e) => {
        e.preventDefault();
        setData((prevData) => ({
          ...prevData,
          [path]: e.target.value,
        }));
      },
    []
  );

  const handleSelectChange = useCallback(
    (path: keyof DataState): ChangeEventHandler<HTMLSelectElement> =>
      (e) => {
        e.preventDefault();
        setData((prevData) => ({
          ...prevData,
          [path]: path === 'gender' ? Number(e.target.value) : e.target.value,
        }));
      },
    []
  );

  // 住所自動入力
  const handleZipCodeChange = (): void => {
    getAddress(data.zipCode).then((address) => {
      if (!address.results) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            zipCode: {
              _errors: [address.message],
            },
          };
        });
        return;
      }
      setData((prevData) => ({
        ...prevData,
        prefecture: address.prefecture,
        municipality: address.municipality,
      }));
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          zipCode: {
            _errors: [],
          },
        };
      });
    });
  };

  const validateForm = (updatedData: DataState) => {
    const result = UserInfoSchema.safeParse(updatedData);
    if (!result.success) {
      setErrors(result.error.format());
      return false;
    }
    setErrors({});
    return true;
  };

  const submitForm = (data: DataState) => {
    const date = new Date(Number(data.year), Number(data.month) - 1, Number(data.day));
    const updatedData: User.AsObject = {
      ...data,
      birthday: date.toISOString(),
    };
    updateUser.mutate({
      user: updatedData,
    });
  };

  const handleSubmit = async (): Promise<void> => {
    if (validateForm(data)) {
      submitForm(data);
    } else {
      return Promise.reject('Validation failed for at least one form field');
    }
  };

  useEffect(() => {
    setData(
      _.merge(_.cloneDeep(user), {
        year: new Date(user.birthday).getFullYear().toString(),
        month: (new Date(user.birthday).getMonth() + 1).toString(),
        day: new Date(user.birthday).getDate().toString(),
      })
    );
  }, [user]);

  return {
    data,
    errors,
    handleInputChange,
    handleSelectChange,
    handleSubmit,
    handleZipCodeChange,
  };
};
